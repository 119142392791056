import { LitElement, html, css } from 'lit';
import '../packs/modal';
import '../packs/orange_button';
import '../packs/cancel_button';

class RequireLoginModal extends LitElement {
  static properties = {
    isActive: { type: Boolean, reflect: true }
  }
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.isActive = false;
  } 
  updated(changedProperties) {
    
  }
  static styles = css`
  .modal-calender-image {
    width: 100%;
    max-width: 500px;
    margin-bottom: 24.6px;
  }
  .modal-text-content {
    margin-bottom: 32px;
  }
  .modal-dialog {
    margin-top: 100px;
  }
  .login-button {
    margin-bottom: 32px;
    a {
      text-decoration: none;
    }
  }
  .sign-up-link {
    margin-bottom: 12px;
    a {
      color: #00818F;
    }
  }
  .cancel-button {
    display: flex;
    justify-content: end;
    line-height: 150%;
  }

  .emphasis-message {
    font-weight: 700
  }
  `
  close() {
    this.dispatchEvent(new CustomEvent('close'));
  }
  render() {
    console.log(this.isActive)
    return html`
    <modal-template .isOpen=${this.isActive} @close="${this.close}">
      <div slot="header">
        <span>ログインが必要です</span>
      </div>
      <div slot="content">
        <div class="modal-body">
          <img src="/images/modal_calender_img.png" class="modal-calender-image">
          <div class="modal-text-content">
            スポレボに会員登録すると、スポーツ施設検索時に<span class="emphasis-message">「利用可能な空き枠があるかどうか」</span>を条件として設定することが可能です。
            <br>
            <br>
            <span class="emphasis-message">利用したい日時に空いている施設だけ探せるので</span>「せっかく見つけた施設なのに、予約で埋まってて使えない......。」そんなお悩みをサクッと解決できちゃいます！
          </div>
          <div class="login-button">
            <a href="/login">
              <orange-button
                isDisplayRightArrow="true"
              >
                ログインして空き状況を検索
              </orange-button>
            </a>
          </div>
          <div class="sign-up-link">
            <a href="/sign_up">新規会員はこちら</a>
          </div>
          <div class="cancel-button">
            <cancel-button @click="${this.close}"/>
          </div>
        </div>
      </div>
    </modal-template>
    `;
  }
}
customElements.define('require-login-modal', RequireLoginModal);