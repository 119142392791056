import { LitElement, html, css } from 'lit';

class ModalTemplate extends LitElement {
  static properties = {
    isOpen: { type: Boolean, reflect: true }
  }
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.isOpen = false;
  } 
  updated(changedProperties) {
    
  }
  static styles = css`
  .fadein {
    animation: fadeIn 0.5s ease 0s 1 normal;
  }
  @keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
  }
  .modal-background {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 0.5rem;
    box-sizing: border-box;
    .modal-wrap {
      max-width: 500px;
      margin: 1.75rem auto;
      margin-top: 100px;
      .modal {
        text-align: start;
        box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
        border-radius: 6px;
        border: none;
        background-color: white;
        padding: 24px;
        .modal-header {
          margin-bottom: 24px;
          display: flex;
          justify-content: space-between;
          color: #263238;
          font-family: "Noto Sans JP";
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; 
          .close-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
            .close-char {
              color: #999;
              font-size: 1.5rem;
              font-weight: 500;
              text-shadow: 0 1px 0 #ffffff;
              opacity: .5;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  `
  close() {
    this.dispatchEvent(new CustomEvent('close'));
  }
  render() {
    return html`
    <div class="modal-background fadein" style="display: ${this.isOpen ? 'block' : 'none'};">
      <div class="modal-wrap">
        <div class="modal">
          <div class="modal-header">
            <slot name="header"></slot>
            <div class="close-button">
              <span aria-hidden="true" class="close-char" @click="${this.close}">×</span>
            </div>
          </div>
          <div class="modal-content">
            <slot name="content"></slot>
          </div>
        </div>
      </div>
    </div>
    `;
  }
}
if (!customElements.get('modal-template')) {
  customElements.define('modal-template', ModalTemplate);
}