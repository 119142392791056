import { LitElement, html, css } from 'lit';
import '../packs/modal';
import '../packs/add_button';
import '../packs/cancel_button';

class SelectAvailabilityModal extends LitElement {
  static properties = {
    isActive: { type: Boolean, reflect: true },
    items: { type: Array, reflect: true },
    isPaidUser: { type: Boolean, reflect: true},
    isAllowedEmptyDate: { type: Boolean, reflect: true},
    hasCustomerId: { type: Boolean, reflect: true},

    selectedItems: { type: Array, reflect: true, attribute: false },
    startDate: { type: String, reflect: true, },
    endDate: { type: String, reflect: true },
  }
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.isActive = false;
    this.isAllowedEmptyDate = false;
  } 
  updated(changedProperties) {
    
  }
  minDate() {
    const  todaySet = new Date();
    const YYYY = todaySet.getFullYear();
    const MM = ('00' + (todaySet.getMonth()+1)).slice(-2);
    const DD = ('00' + todaySet.getDate()).slice(-2);
    return YYYY + '-' + MM + '-' + DD + ' 00:00:00';
  }
  maxDate() {
    const  todaySet = new Date();
    if(this.isPaidUser){
      todaySet.setDate(todaySet.getDate() + 30);
      const YYYY = todaySet.getFullYear();
      const MM = ('00' + (todaySet.getMonth()+1)).slice(-2);
      const DD = ('00' + todaySet.getDate()).slice(-2);
      return YYYY + '-' + MM + '-' + DD + ' 23:59:59';
    }
    else {
      todaySet.setDate(todaySet.getDate() + 4);
      const YYYY = todaySet.getFullYear();
      const MM = ('00' + (todaySet.getMonth()+1)).slice(-2);
      const DD = ('00' + todaySet.getDate()).slice(-2);
      return YYYY + '-' + MM + '-' + DD + ' 23:59:59';
    }
  }

  startDateLabel(){
    const startDate = new Date(this.startDate);
    const YYYY = startDate.getFullYear();
    const MM = ('00' + (startDate.getMonth()+1)).slice(-2);
    const DD = ('00' + startDate.getDate()).slice(-2);
    const hh = ('00' + startDate.getHours()).slice(-2);
    const mm = ('00' + startDate.getMinutes()).slice(-2);
    return YYYY + '年' + MM + '月' + DD + '日' + hh + ':' + mm;
  }
  endDateLabel(){
    const endDate = new Date(this.endDate);
    const YYYY = endDate.getFullYear();
    const MM = ('00' + (endDate.getMonth()+1)).slice(-2);
    const DD = ('00' + endDate.getDate()).slice(-2);
    const hh = ('00' + endDate.getHours()).slice(-2);
    const mm = ('00' + endDate.getMinutes()).slice(-2);
    return YYYY + '年' + MM + '月' + DD + '日' + hh + ':' + mm;
  }
  static styles = css`
  .date-range-announce {
    display: flex;
    margin-top: 64px;
    font-size: 12px;
    line-height: 150%;
    font-weight: 400;
    color: #A8ABA6;
  }
  .asterisk {
    width: 16px;
  }
  .upgrade-paid-user-link {
    margin-top: 16px;
    margin-left: 16px;
  }
  .upgrade-paid-user-link a {
    color: #00818F;
  }
  .paid-user-description-link {
    margin-top: 24px;
    margin-left: 16px;
  }
  .paid-user-description-link a {
    color: #00818F;
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .search-availability-modal-body {
    height: 460px;
  }
  .start-date-form {
    margin-bottom: 24px;
  }
  `
  close() {
    this.dispatchEvent(new CustomEvent('close'));
  }
  clickAddButton(){
    if((!this.startDate || !this.endDate) && !this.isAllowedEmptyDate){
      alert("日付を選択してください");
      return;
    }
    else if(this.startDate && !this.endDate){
      alert("終了日時を選択してください");
      return;
    }
    else if(!this.startDate && this.endDate){
      alert("開始日時を選択してください");
      return;
    }
    else if(new Date(this.startDate) > new Date(this.endDate)){
      alert("終了日時は開始日時より後にしてください");
      return;
    }
    else if(!this.isPaidUser && new Date(this.endDate) - new Date() > 1000 * 60 * 60 * 24 * 5){
      alert("本日を含めて5日間より長い期間を設定できません");
      return;
    }
    else if(this.isPaidUser && new Date(this.endDate) - new Date() > 1000 * 60 * 60 * 24 * 30){
      alert("本日を含めて30日間より長い期間を設定できません");
      return;
    }
    else if(new Date(this.startDate) < new Date()){
      alert("過去の日時は設定できません");
      return;
    }
    else if(new Date(this.endDate) < new Date()){
      alert("過去の日時は設定できません");
      return;
    }
    this.dispatchEvent(new CustomEvent('add-availability',{
      detail: {
        startDate: this.startDate,
        endDate: this.endDate,
        label: this.startDateLabel() + " ~ " + this.endDateLabel()
      }
    }));
    this.close();
  }
  handleStartDateInput(e) {
    this.startDate = e.target.value;
  }
  handleEndDateInput(e) {
    this.endDate = e.target.value;
  }
  render() {
    return html`
    <modal-template .isOpen=${this.isActive} @close="${this.close}">
      <div slot="header">
        <span>期間を選択してください</span>
      </div>
      <div slot="content">
        <div class="search-availability-modal-body">
          <div class="start-date-form">
            <div class="availability-form-label">開始</div>
            <input type="datetime-local" value="${this.startDate}" min="${this.minDate()}" max="${this.maxDate()}" @input="${this.handleStartDateInput}">
          </div>
          <div class="end-date-form">
            <div class="availability-form-label">終了</div>
            <input type="datetime-local" value="${this.endDate}" min="${this.minDate()}" max="${this.maxDate()}" @input="${this.handleEndDateInput}">
          </div>
          ${ this.isPaidUser ? '' : html`
            <div class="date-range-announce">
              <div class="asterisk">※</div>
              <div>【本日を含めて5日間】のみ指定可能となります。5日間より長い期間の指定は、スポレボPlus登録後に利用可能となります（最大1ヶ月先まで）。</div>
            </div>
            <div class="upgrade-paid-user-link">
              ${this.hasCustomerId ? html`
                <a href="/order_confirm">スポレボPlusに再度登録する</a>
              ` : html`
                <a href="/order_confirm">スポレボPlusを無料で試してみる</a>
              `}
            </div>
            <div class="paid-user-description-link">
              <a href="/about_sporevo_plus" target="_blank">スポレボPlusをもっと詳しく知る</a>
            </div>

          `
          }
        </div>
        <div class="buttons">
          <cancel-button @click="${this.close}"></cancel-button>
          <add-button @click="${this.clickAddButton}"></add-button>
        </div>
      </div>
    </modal-template>
    `;
  }
}
if (!customElements.get('select-availability-modal'))
customElements.define('select-availability-modal', SelectAvailabilityModal);