

import { LitElement, html, css } from 'lit';

class NoticeAnnotation extends LitElement {
  static properties = {
    text: { type: String, reflect: true },
    number: { type: Number, reflect: true }
  }
  static styles = css`
    .notice-annotation {
      display: flex;
      gap: 4px;
      color: #A8ABA6;
      font-family: "Noto Sans JP";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 18px */
    }
  `
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
    this.number = null;
  }
  render() {
    return html`
      <div class="notice-annotation">
        <div>※${this.number ? this.number : ''}</div>
        <div>${this.text}</div>
      </div>
    `;
  }
}
if (!customElements.get('notice-annotation'))
  customElements.define('notice-annotation', NoticeAnnotation);