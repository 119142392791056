import { LitElement, html, css } from 'lit';

class AddButton extends LitElement {
  static properties = {
  }
  constructor() {
    super();
    this.attachShadow({ mode: "open" });
  } 
  updated(changedProperties) {
    
  }
  static styles = css`
    button {
      color: white;
      background-color: #D76315;
      border-radius: 8px;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      font-family: "Noto Sans JP";
      border: none;

      display: flex;
      padding: 0px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 26px;
      &:hover {
        cursor: pointer;
      }
    }
  `
  render() {
    return html`
      <button>
        <slot>追加</slot>
      </button>
    `;
  }
}
if (!customElements.get('add-button'))
customElements.define('add-button', AddButton);